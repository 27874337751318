import {
  POST_LOGIN_REQUEST,
  POST_LOGIN_SUCCESS,
  POST_LOGIN_FAILURE,
  POST_SIGNUP_REQUEST,
  POST_SIGNUP_SUCCESS,
  POST_SIGNUP_FAILURE,
  GET_LOGIN_PROFILE_REQUEST,
  GET_LOGIN_PROFILE_SUCCESS,
  GET_LOGIN_PROFILE_FAILURE,
  POST_SIGNUP_RESET,
} from "./actionTypes";

const initialState = {
  pending: false,
  waitForLoginResponse: false,
  login: null,
  error: null,

  waitForSignupResponse: false,
  signUpStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case POST_LOGIN_REQUEST:
    case GET_LOGIN_PROFILE_REQUEST:
      return {
        ...state,
        waitForLoginResponse: true,
      };
    case POST_LOGIN_SUCCESS:
    case GET_LOGIN_PROFILE_SUCCESS:
      return {
        ...state,
        waitForLoginResponse: false,
        login: action.payload.login,
        error: null,
      };
    case POST_LOGIN_FAILURE:
    case GET_LOGIN_PROFILE_FAILURE:
      return {
        ...state,
        waitForLoginResponse: false,
        login: null,
        error: action.payload.error,
      };
    case POST_SIGNUP_RESET:
      return {
        ...state,
        waitForSignupResponse: false,
        signUpStatus: null,
        error: null,
      };
    case POST_SIGNUP_REQUEST:
      return {
        ...state,
        waitForSignupResponse: true,
        signUpStatus: null,
      };
    case POST_SIGNUP_SUCCESS:
      return {
        ...state,
        waitForSignupResponse: false,
        signUpStatus: 1,
        error: null,
      };
    case POST_SIGNUP_FAILURE:
      return {
        ...state,
        waitForSignupResponse: false,
        signUpStatus: 0,
        error: action.payload.error,
      };
    default:
      return {
        ...state,
      };
  }
};
