export const POST_LOGIN_REQUEST = "POST_LOGIN_REQUEST";
export const POST_LOGIN_SUCCESS = "POST_LOGIN_SUCCESS";
export const POST_LOGIN_FAILURE = "POST_LOGIN_FAILURE";

export const GET_LOGIN_PROFILE_REQUEST = "GET_LOGIN_PROFILE_REQUEST";
export const GET_LOGIN_PROFILE_SUCCESS = "GET_LOGIN_PROFILE_SUCCESS";
export const GET_LOGIN_PROFILE_FAILURE = "GET_LOGIN_PROFILE_FAILURE";

export const POST_SIGNUP_RESET = "POST_SIGNUP_RESET";
export const POST_SIGNUP_REQUEST = "POST_SIGNUP_REQUEST";
export const POST_SIGNUP_SUCCESS = "POST_SIGNUP_SUCCESS";
export const POST_SIGNUP_FAILURE = "POST_SIGNUP_FAILURE";
