import axios from "axios";
import { toast } from "react-toastify";
const { REACT_APP_BASE_URL } = process.env;

const Api = axios.create({
  baseURL: `${REACT_APP_BASE_URL}`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

Api.interceptors.request.use(function (config) {
  const token = localStorage.getItem("accessToken");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

Api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    toast.error(error?.response?.data?.message);
    if (error?.response?.status === 401 || error?.response?.status === 500) {
      localStorage.clear();
    }
    return Promise.reject(error);
  }
);

export default Api;
