import {
  POST_LOGIN_REQUEST,
  POST_LOGIN_SUCCESS,
  POST_LOGIN_FAILURE,
  POST_SIGNUP_REQUEST,
  POST_SIGNUP_SUCCESS,
  POST_SIGNUP_FAILURE,
  GET_LOGIN_PROFILE_REQUEST,
  GET_LOGIN_PROFILE_SUCCESS,
  GET_LOGIN_PROFILE_FAILURE,
  POST_SIGNUP_RESET,
} from "./actionTypes";

//--------------------------------Login---------------------//
export const postLoginRequest = (payload) => ({
  type: POST_LOGIN_REQUEST,
  payload,
});

export const postLoginSuccess = (payload) => ({
  type: POST_LOGIN_SUCCESS,
  payload,
});

export const postLoginFailure = (payload) => ({
  type: POST_LOGIN_FAILURE,
  payload,
});

//--------------------------------GET_LOGIN_PROFILE---------------------//
export const getLoginProfileRequest = (payload) => ({
  type: GET_LOGIN_PROFILE_REQUEST,
  payload,
});

export const getLoginProfileSuccess = (payload) => ({
  type: GET_LOGIN_PROFILE_SUCCESS,
  payload,
});

export const getLoginProfileFailure = (payload) => ({
  type: GET_LOGIN_PROFILE_FAILURE,
  payload,
});

//--------------------------------Signup---------------------//
export const postSignupReset = (payload) => ({
  type: POST_SIGNUP_RESET,
  payload,
});

export const postSignupRequest = (payload) => ({
  type: POST_SIGNUP_REQUEST,
  payload,
});

export const postSignupSuccess = (payload) => ({
  type: POST_SIGNUP_SUCCESS,
  payload,
});

export const postSignupFailure = (payload) => ({
  type: POST_SIGNUP_FAILURE,
  payload,
});
