import React, { Suspense, lazy, useEffect } from "react";
import { Switch, Redirect } from "react-router-dom";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";

//css
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/theme-css.css";
import "../assets/css/style.css";
// import "../assets/css/style.min.css";
import "../assets/css/responsive.css";

// const  = lazy(() => import(""));

import SplashScreen from "../components/SplashScreen/SplashScreen";
import LazyLoad from "./LazyLoad";
import ScrollToTop from "../components/ScrollToTop/ScrollToTop";
import { isLogin } from "../utils/utils";
import { useDispatch } from "react-redux";
import { getLoginProfileRequest } from "../store/auth/actions";

const Layout = LazyLoad(import("../layouts"));

const Login = LazyLoad(import("../pages/Login/Login"));
const ForgotPassword = LazyLoad(
  import("../pages/ForgotPassword/ForgotPassword")
);
const SignUp = LazyLoad(import("../pages/SignUp/SignUp"));
const Home = LazyLoad(import("../pages/Home/Home"));
const AllCategory = LazyLoad(import("../pages/AllCategory/AllCategory"));
const Category = LazyLoad(import("../pages/Category/Category"));
const Profile = LazyLoad(import("../pages/Profile/Profile"));
const Account = LazyLoad(import("../pages/Account/Account"));
const Invite = LazyLoad(import("../pages/Invite/Invite"));
const Following = LazyLoad(import("../pages/Following/Following"));
const BookingZoom = LazyLoad(import("../pages/BookingZoom/BookingZoom"));
const EnrollAsTalent = LazyLoad(
  import("../pages/EnrollAsTalent/EnrollAsTalent")
);
const GetApp = LazyLoad(import("../pages/GetApp/GetApp"));

const Jobs = LazyLoad(import("../pages/Jobs/Jobs"));
const Faqs = LazyLoad(import("../pages/Faqs/Faqs"));
const PrivacyPolicy = LazyLoad(import("../pages/PrivacyPolicy/PrivacyPolicy"));
const Terms = LazyLoad(import("../pages/Terms/Terms"));
const Team = LazyLoad(import("../pages/Team/Team"));

const Accessibility = LazyLoad(import("../pages/Accessibility/Accessibility"));

const Store = LazyLoad(import("../pages/Store/Store"));

const Router = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLogin()) {
      dispatch(
        getLoginProfileRequest({
          "x-access-token": localStorage.getItem("accessToken"),
        })
      );
    }
  }, []);

  return (
    <Suspense fallback={<SplashScreen />}>
      <Layout>
        <ScrollToTop />
        <Switch>
          <PublicRoute path="/login" component={Login} exact />
          <PublicRoute path="/signup" component={SignUp} exact />
          <PublicRoute
            path="/forgot-password"
            component={ForgotPassword}
            exact
          />

          <PublicRoute path="/allCategory" component={AllCategory} exact />
          <PublicRoute path="/category/:slug" component={Category} exact />

          <PublicRoute path="/booking/:slug" component={Category} exact />
          <PublicRoute
            path="/booking-zoom/:slug"
            component={BookingZoom}
            exact
          />

          <PublicRoute path="/profile" component={Profile} exact />
          <PublicRoute path="/account" component={Account} exact />
          <PublicRoute path="/invite" component={Invite} exact />
          <PublicRoute path="/following" component={Following} exact />

          <PublicRoute path="/get-app" component={GetApp} exact />
          <PublicRoute path="/jobs" component={Jobs} exact />
          <PublicRoute path="/faqs" component={Faqs} exact />
          <PublicRoute path="/privacy-policy" component={PrivacyPolicy} exact />
          <PublicRoute path="/terms" component={Terms} exact />
          <PublicRoute path="/team" component={Team} exact />

          <PublicRoute path="/accessibility" component={Accessibility} exact />

          <PublicRoute path="/store" component={Store} exact />

          <PublicRoute
            path="/enroll-as-talent"
            component={EnrollAsTalent}
            exact
          />

          <PublicRoute path="/" component={Home} exact />

          <Redirect to="/" />
        </Switch>
      </Layout>
    </Suspense>
  );
};

export default Router;
