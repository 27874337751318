import { all, call, put, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import {
  getLoginProfileFailure,
  getLoginProfileSuccess,
  postLoginFailure,
  postLoginSuccess,
  postSignupFailure,
  postSignupSuccess,
} from "./actions";
import {
  GET_LOGIN_PROFILE_REQUEST,
  POST_LOGIN_REQUEST,
  POST_SIGNUP_REQUEST,
} from "./actionTypes";
import { login, loginProfile, signUp } from "./../../Api/auth";

function* loginRequest(action) {
  try {
    const response = yield call(login, action.payload);
    if (response.status === 200) {
      localStorage.setItem("accessToken", response.data.data.accessToken);
      yield put(
        postLoginSuccess({
          login: response.data.data,
        })
      );
    } else {
      yield put(
        postLoginFailure({
          error: response.data.message,
        })
      );
    }
    console.log(":::", response);
  } catch (error) {
    yield put(
      postLoginFailure({
        error: error.response,
      })
    );
  }
}

function* loginProfileRequest(action) {
  try {
    const response = yield call(loginProfile, action.payload);
    if (response.status === 200) {
      yield put(
        getLoginProfileSuccess({
          login: response.data.data,
        })
      );
    } else {
      yield put(
        getLoginProfileFailure({
          error: response.data.message,
        })
      );
    }
  } catch (error) {
    yield put(
      getLoginProfileFailure({
        error: error.response,
      })
    );
  }
}

function* signupRequest(action) {
  try {
    const response = yield call(signUp, action.payload);
    if (response.status === 200) {
      yield put(postSignupSuccess({}));
    } else {
      yield put(
        postSignupFailure({
          error: response.data.message,
        })
      );
    }
  } catch (error) {
    yield put(
      postSignupFailure({
        error: error.response,
      })
    );
  }
}

function* loginSaga() {
  yield all([takeLatest(POST_LOGIN_REQUEST, loginRequest)]);
  yield all([takeLatest(GET_LOGIN_PROFILE_REQUEST, loginProfileRequest)]);
  yield all([takeLatest(POST_SIGNUP_REQUEST, signupRequest)]);
}

export default loginSaga;
