import React, { useEffect } from "react";

// const image = React.lazy(() => import("../../assets/images/logo_white.svg"));

import image from "../../assets/images/logo_white.svg";

const SplashScreen = () => {
  return (
    <div id="preloader">
      <div id="status"></div>
    </div>
  );
};

export default SplashScreen;
