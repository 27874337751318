import React from "react";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Route from "./routes/Router";

const App = () => {
  return (
    <>
      <Route />
      <ToastContainer position="bottom-left" />
    </>
  );
};

export default App;
