import Api from "./api";

export async function login(data) {
  return await Api.post("/login", data).then((res) => {
    return res;
  });
}
export async function loginProfile(data) {
  return await Api.get("/profile", {
    headers: data,
  }).then((res) => {
    return res;
  });
}

export async function signUp(data) {
  return await Api.post("/register", data).then((res) => {
    return res;
  });
}
